function etiVFXParseExcelData(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("ID Ext");
  headerNames.push("Ano Letivo");
  headerNames.push("Agrupamento");
  headerNames.push("Escola");
  headerNames.push("Horário");
  headerNames.push("Nome");
  headerNames.push("Ano Escolaridade");
  headerNames.push("NIF");
  headerNames.push("NEE");
  headerNames.push("Comparticipação Familiar");
  headerNames.push("Data Registo");

  fileData.forEach((element) => {
    element["ID Ext"] = JSON.stringify(JSON.parse(element["id"].toString()));
    delete element.id;
    element["Agrupamento"] = parseCluster(element["Agrupamento de Escolas"]);
    //Parse schools
    element["Escola"] = getSchool(element);
    element["Horário"] = getSchedule(element);
    element["Nome"] = element["Nome completo"];
    element["Ano Escolaridade"] = parseSchoolYear(
      element["Ano de Escolaridade"],
      element["Ano de Escolaridade (value)"]
    );
    element["NIF"] = element["NIF (número de identificação fiscal)"];
    element["NEE"] = element["Aluno com NEE"];
    element["Data Registo"] = new Date(element["Submetido em"]).getTime();
    element["Ano Letivo"] = getYear(element["Data Registo"]);
    element["Comparticipação Familiar"] = getComparticipation(element);
  });

  function parseCluster(cluster) {
    switch (cluster) {
      case "Agrupamento de Escolas de Alhandra, Sobralinho e São João dos Montes":
        return "Alhandra, Sobralinho e S. João dos Montes";
      case "Agrupamento de Escolas Alves Redol":
        return "Alves Redol";
      case "Agrupamento de Escolas do Bom Sucesso":
        return "Bom Sucesso";
      case "Agrupamento de Escolas D. António de Ataíde":
        return "D. António de Ataíde";
      case "Agrupamento de Escolas do Forte da Casa":
        return "Forte da Casa";
      case "Agrupamento de Escolas Pedro Jacques de Magalhães":
        return "Pedro Jacques de Magalhães";
      case "Agrupamento de Escolas da Póvoa de Sta. Iria":
        return "Póvoa de Santa Iria";
      case "Agrupamentos de Escolas Prof. Reynaldo dos Santos":
        return "Professor Reynaldo dos Santos";
      case "Agrupamento de Escolas de Vialonga":
        return "Vialonga";
      default:
        break;
    }
  }

  function getSchool(element) {
    for (const field in element) {
      if (element.hasOwnProperty(field)) {
        let value = element[field];
        if (
          value &&
          (field.indexOf("Estabelecimento de Ensino") != -1 || field.indexOf("Estabelecimentos de Ensino") != -1)
        ) {
          if (value.indexOf("Escola Básica") != -1) {
            value = value.replace("Escola Básica", "EB");
          } else if (value.indexOf("Jardim de Infância") != -1) {
            value = value.replace("Jardim de Infância", "JI");
          }
          return value;
        }
      }
    }
  }

  function parseSchoolYear(schoYear1, schoYear2) {
    let schoolYearToBeParsed;

    if (schoYear1) {
      schoolYearToBeParsed = schoYear1;
    } else if (schoYear2) {
      schoolYearToBeParsed = schoYear2;
    } else {
      return null;
    }

    if (schoolYearToBeParsed == "Jardim-de-Infância" || schoolYearToBeParsed == "jardim_de_infncia") {
      return "0";
    }
    if (schoolYearToBeParsed == "1.º ano" || schoolYearToBeParsed == "1_ano" || schoolYearToBeParsed == "1º") {
      return "1";
    }
    if (schoolYearToBeParsed == "2.º ano" || schoolYearToBeParsed == "2_ano" || schoolYearToBeParsed == "2º") {
      return "2";
    }
    if (schoolYearToBeParsed == "3.º ano" || schoolYearToBeParsed == "3_ano" || schoolYearToBeParsed == "3º") {
      return "3";
    }
    if (schoolYearToBeParsed == "4.º ano" || schoolYearToBeParsed == "4_ano" || schoolYearToBeParsed == "4º") {
      return "4";
    }

    return null;
  }

  function getSchedule(element) {
    if (
      element["EDUCAÇÃO PRÉ-ESCOLAR - Atividades de Animação e Apoio à Família (Prolongamento parcial (15h-17h30))"]
    ) {
      return "AAAF - Prolongamento 15h30-17h30";
    }
    if (
      element[
        "EDUCAÇÃO PRÉ-ESCOLAR - Atividades de Animação e Apoio à Família (Horário completo (inclui o acolhimento da manhã, o prolongamento ao final do dia e as pausas letivas, com exceção do mês de agosto))"
      ] == "X"
    ) {
      return "AAAF - Horário completo";
    }
    if (element["1º CICLO DO ENSINO BÁSICO  (Acolhimento (manhã))"]) {
      return "CAF - Acolhimento";
    }
    if (element["1º CICLO DO ENSINO BÁSICO  (Prolongamento (final do dia))"]) {
      return "CAF - Prolongamento";
    }
    if (
      element[
        "1º CICLO DO ENSINO BÁSICO  (Horário completo (inclui o acolhimento da manhã, o prolongamento ao final do dia e as pausas letivas, com exceção do mês de agosto))"
      ]
    ) {
      return "CAF - Horário completo";
    }
  }

  function getYear(registerDateMs) {
    if (registerDateMs) {
      let date = new Date(registerDateMs);
      if (date.getMonth() >= 3) {
        return date.getFullYear().toString();
      } else {
        return (date.getFullYear() - 1).toString();
      }
    }
  }

  function getComparticipation(element) {
    let fromTableCluster = scope.genericScope
      .getFromTableData("Clusters")
      .filter((el) => el.cluster == element["Agrupamento"]);
    let fromTableSchool = scope.genericScope.getFromTableData("Schools").filter((el) => el.school == element["Escola"]);
    let fromTableSchedule = scope.genericScope
      .getFromTableData("VFX_ETI_Schedules")
      .filter((el) => el.schedule == element["Horário"]);
    if (fromTableCluster[0] && fromTableSchool[0] && fromTableSchedule[0]) {
      let fromTableComparticipation = scope.genericScope
        .getFromTableData("VFX_ETI_Fam_Comparticipations")
        .filter(
          (el) =>
            el.cluster == fromTableCluster[0].id &&
            el.school == fromTableSchool[0].id &&
            el.schedule == fromTableSchedule[0].id
        );
      if (fromTableComparticipation[0]) {
        return fromTableComparticipation[0].comparticipation;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

function aseVFXParseExcelData(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("ID Ext");
  headerNames.push("Ano Letivo");
  headerNames.push("Ano Escolaridade");
  headerNames.push("Agrupamento");
  headerNames.push("Escola");
  headerNames.push("Nome");
  headerNames.push("Escalão ASE");
  headerNames.push("Data Atribuição Escalão");
  headerNames.push("Observações");

  let id;

  for (let j = 0; j < scope.data.length; j++) {
    const element = scope.data[j];
    if (element.externalId) {
      if (Number(element.externalId) > id) {
        id = Number(element.externalId);
      }
    }
  }

  if (id && !Number.isNaN(id)) {
    id += 1;
  } else {
    id = 1;
  }

  fileData.forEach((element) => {
    element["ID Ext"] = id;
    id += 1;
    element["Ano Letivo"] = element["Ano"];
    element["Ano Escolaridade"] = element["Ano de Escolaridade"];
    element["Agrupamento"] = element["Agrup"];
    //Parse schools
    element["Escola"] = element["Escola"];
    element["Nome"] = element["Nome"];
    element["Escalão ASE"] = parseASEEchelon(element["Escalão "]);
    element["Data Atribuição Escalão"] = new Date(element["Submetido em"]).getTime();
    element["Observações"] = element["Obs"];
  });

  function parseASEEchelon(echelon) {
    switch (echelon) {
      case "A":
        return "A";
      case "B":
        return "B";
      case "C":
        return "C";
      default:
        return null;
    }
  }
}

function transportsVFXParseExcelData(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("ID Ext");
  headerNames.push("Tipo Candidatura");
  headerNames.push("Ano");
  headerNames.push("Tipo Curso");
  headerNames.push("Nível Ensino");
  headerNames.push("Ano Escolaridade");
  headerNames.push("NIF");
  headerNames.push("Nome");
  headerNames.push("Situação Aluno");
  headerNames.push("Data Situação Aluno");
  headerNames.push("Data Nascimento");
  headerNames.push("Idade");
  headerNames.push("Morada");
  headerNames.push("Nº documento de identificação");
  headerNames.push("Telemóvel");
  headerNames.push("E-mail");
  headerNames.push("Agrupamento/ENA");
  headerNames.push("Escola");
  headerNames.push("Localidade");
  headerNames.push("Cod Postal");
  headerNames.push("Informaçao de Transporte");

  let filledImporterFields = importerFieldsCheck(scope.importerFields);
  let uploadYear;

  if (filledImporterFields.indexOf("year") != -1) {
    uploadYear = scope.importerFields.year;
  }

  fileData.forEach((element) => {
    // New fields, external id and application type
    // Verificar resto das escolas

    element["ID Ext"] = JSON.stringify(JSON.parse(element["IdRegisto"].toString()));
    let lastYearGridDataElWithSameNif = null;
    for (let k = 0; k < scope.data.length; k++) {
      let gridDataElement = scope.data[k];
      if (gridDataElement.nif == element["NIF"] && Number(gridDataElement.year) == Number(uploadYear) - 1) {
        lastYearGridDataElWithSameNif = JSON.parse(JSON.stringify(gridDataElement));
        lastYearGridDataElWithSameNif.year = uploadYear;
        lastYearGridDataElWithSameNif.applicationType = "Continuidade";
        break;
      }
    }
    if (lastYearGridDataElWithSameNif != null) {
      element["Tipo Candidatura"] = lastYearGridDataElWithSameNif.applicationType;
      element["Ano"] = lastYearGridDataElWithSameNif.year;
      if (lastYearGridDataElWithSameNif.courseType != null) {
        element["Tipo Curso"] = scope.genericScope
          .getFromTableData("Class_Plan_Course_Types")
          .filter((el) => el.id == lastYearGridDataElWithSameNif.courseType)[0].courseType;
      }
      if (lastYearGridDataElWithSameNif.educationLevel != null) {
        element["Nível Ensino"] = scope.genericScope
          .getFromTableData("Class_Plan_Education_Levels")
          .filter((el) => el.id == lastYearGridDataElWithSameNif.educationLevel)[0].educationLevel;
      }
      if (lastYearGridDataElWithSameNif.schoolYear != null) {
        element["Ano Escolaridade"] = scope.genericScope
          .getFromTableData("Transp_Scho_Years")
          .filter((el) => el.id == lastYearGridDataElWithSameNif.schoolYear)[0].schoolYear;
      }
      element["NIF"] = lastYearGridDataElWithSameNif.nif;
      element["Nome"] = lastYearGridDataElWithSameNif.name;
      element["Situação Aluno"] = lastYearGridDataElWithSameNif.studentSituation;
      element["Data Situação Aluno"] = lastYearGridDataElWithSameNif.studentSituationMS;
      element["Data Nascimento"] = lastYearGridDataElWithSameNif.birthdayMS;
      element["Idade"] = lastYearGridDataElWithSameNif.age;
      element["Morada"] = lastYearGridDataElWithSameNif.address;
      element["Nº documento de identificação"] = lastYearGridDataElWithSameNif.bi;
      element["Telemóvel"] = lastYearGridDataElWithSameNif.phoneNumb;
      element["E-mail"] = lastYearGridDataElWithSameNif.email;
      element["Agrupamento/ENA"] = element["Agrupamento"];
      element["Escola"] = element["Escola"];
      element["Localidade"] = lastYearGridDataElWithSameNif.locality;
      element["Cod Postal"] = lastYearGridDataElWithSameNif.postalCode;
      element["Informaçao de Transporte"] = lastYearGridDataElWithSameNif.transportInfo;
    } else {
      element["Ano"] = uploadYear;
      element["Tipo Candidatura"] = "1ª vez";
      element["Tipo Curso"] = parseCourseType(element["Curso"]);
      element["Nível Ensino"] = parseEducationLevel(element["Grau de Ensino"]);
      element["Ano Escolaridade"] = parseSchoolYear(element["Ano Esc."]);
      element["Nome"] = element["Nome"];
      element["Situação Aluno"] = "Ativo";
      element["Data Situação Aluno"] = new Date().getTime();
      if (element["Data de nascimento"] != null && element["Data de nascimento"] != "") {
        element["Data Nascimento"] = parseSpecialDate(element["Data de nascimento"]);
      }
      if (element["Data Nascimento"] != null && element["Data Nascimento"] != "") {
        element["Idade"] = Math.abs(new Date(Date.now() - element["Data Nascimento"]).getUTCFullYear() - 1970);
      }
      element["Morada"] = element["Morada, Nº / Andar-Fração, Lugar-Bairro"];
      element["Nº documento de identificação"] =
        element[
          "Nº documento de identificação do aluno (Cartão de Cidadão; Cédula pessoal; Passaporte; titulo de residência)"
        ].toString();
      if (element["Código Postal / Localidade"] != null && element["Código Postal / Localidade"].length > 8) {
        element["Cod Postal"] = element["Código Postal / Localidade"].substring(0, 8);
        element["Localidade"] = element["Código Postal / Localidade"].substring(9);
      }
      element["Agrupamento/ENA"] = element["Agrupamento"];
      element["Escola"] = element["Escola"];
      element["Telemóvel"] = element["Contacto de telemóvel"];
    }
  });

  function parseCourseType(course) {
    switch (course) {
      case "Secundário - Profissional":
        return "Profissional";
      case "3º ciclo - CEF":
        return "CEF";
      case "3º ciclo - PCA":
        return "PCA";
      default:
        return "Regular";
    }
  }

  function parseEducationLevel(educationLevel) {
    switch (educationLevel) {
      case "1º CEB":
        return "1º Ciclo";
      case "2º CEB":
        return "2º Ciclo";
      case "3º CEB":
        return "3º Ciclo";
      case "pré-escolar":
        return "PRE";
      case "Cursos Profissionais":
        return "SEC";
      case "Secundário":
        return "SEC";
      default:
        break;
    }
  }

  function parseSpecialDate(date) {
    if (date) {
      let splitDate = [];
      splitDate = date.split("/");
      if (splitDate.length == 3) {
        return new Date(splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2]).getTime();
      }
    }
  }

  /* function parseCluster(cluster) {
    switch (cluster) {
      case "AE de Alhandra, Sobralinho e São João dos Montes":
        return "Alhandra, Sobralinho e S. João dos Montes";
      case "AE Alves Redol":
        return "Alves Redol";
      case "AE Bom Sucesso":
        return "Bom Sucesso";
      case "AE D. António de Ataíde":
        return "D. António de Ataíde";
      case "AE Forte da Casa":
        return "Forte da Casa";
      case "AE Pedro Jacques de Magalhães":
        return "Pedro Jacques de Magalhães";
      case "AE Póvoa de Santa Iria":
        return "Póvoa de Santa Iria";
      case "AE Professor Reynaldo dos Santos":
        return "Professor Reynaldo dos Santos";
      case "AE de Vialonga":
        return "Vialonga";
      case "Escolas Externas":
        return "Agrupamentos ou ES Externas";
      case "Escola Secundária Gago Coutinho":
        return "Escola Secundária Gago Coutinho";
      case "ES Gago Coutinho":
        return "Escola Secundária Gago Coutinho";
      default:
        break;
    }
  }

  function parseSchool(school) {
    switch (school) {
      case "ES Gago Coutinho":
        return "Sec. Gago Coutinho";
      case "ES Forte da Casa":
        return "Sec. Forte Da Casa";
      case "EB D. António de Ataíde, Castanheira do Ribatejo":
        return "EB D. António de Ataíde";
      case "EB Soeiro Pereira Gomes, Alhandra":
        return "EB Soeiro Pereira Gomes";
      case "ES Alves Redol":
        return "Sec. Alves Redol";
      case "EB e Secundária Professor Reynaldo dos Santos":
        return "Sec. Reynaldo Dos Santos";
      case "EB Bom Sucesso, Alverca do Ribatejo":
        return "AE Bom Sucesso";
      case "EB e Secundária de Vialonga":
        return "EB Vialonga";
      default:
        return school;
    }
  } */

  function getSchool(element) {
    for (const field in element) {
      if (element.hasOwnProperty(field)) {
        let value = element[field];
        if (
          value &&
          (field.indexOf("Estabelecimento de Ensino") != -1 || field.indexOf("Estabelecimentos de Ensino") != -1)
        ) {
          if (value.indexOf("Escola Básica") != -1) {
            value = value.replace("Escola Básica", "EB");
          } else if (value.indexOf("Jardim de Infância") != -1) {
            value = value.replace("Jardim de Infância", "JI");
          }
          return value;
        }
      }
    }
  }

  function parseSchoolYear(schoYear) {
    if (schoYear == "pré-escolar") {
      return "0";
    }
    if (schoYear == "1º ano") {
      return "1";
    }
    if (schoYear == "2º ano") {
      return "2";
    }
    if (schoYear == "3º ano") {
      return "3";
    }
    if (schoYear == "4º ano") {
      return "4";
    }
    if (schoYear == "5º ano") {
      return "5";
    }
    if (schoYear == "6º ano") {
      return "6";
    }
    if (schoYear == "7º ano") {
      return "7";
    }
    if (schoYear == "8º ano") {
      return "8";
    }
    if (schoYear == "9º ano") {
      return "9";
    }
    if (schoYear == "10º ano") {
      return "10";
    }
    if (schoYear == "11º ano") {
      return "11";
    }
    if (schoYear == "12º ano") {
      return "12";
    }

    return null;
  }
}

function aecVFXRecordParseExcelData(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("Ano");
  headerNames.push("Agrupamento");
  headerNames.push("Escola");
  headerNames.push("Nome");

  for (let i = 0; i < fileData.length; ) {
    let element = fileData[i];
    if (element.splitFlag) {
      break;
    }
    for (let y = 2014; y < 2022; y++) {
      if (element[y + ""] != null && element[y + ""] != "") {
        let elYearRegNumber = Number(element[y + ""]) + 1;
        for (let r = 1; r < elYearRegNumber; r++) {
          let newElement = {};
          newElement["Ano"] = y;
          newElement["Agrupamento"] = element["Agrupamento"];
          newElement["Escola"] = element["Escola"];
          newElement["Nome"] = r + "";
          newElement.splitFlag = true;
          fileData.push(newElement);
        }
      }
    }
    fileData.splice(i, 1);
  }
}

/* function aecVFXSigaParseExcelData(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("Ano");
  headerNames.push("Agrupamento");
  headerNames.push("Escola");
  headerNames.push("Tipo Curso");
  headerNames.push("Nível Ensino");
  headerNames.push("Ano Escolaridade");
  headerNames.push("Turma");
  headerNames.push("Nome");
  headerNames.push("NIF");
  headerNames.push("Data Início Serviço");

  let filledImporterFields = importerFieldsCheck(scope.importerFields);
  fileData.forEach((element) => {
    if (filledImporterFields.indexOf("year") != -1) {
      element["Ano"] = scope.importerFields.year;
    } else {
      element["Ano"] = new Date().getFullYear().toString();
    }
    element["Agrupamento"] = element["Agrupamento"];
    element["Escola"] = element["Escola"];
    element["Tipo Curso"] = parseCourseType(element["Curso"]);
    element["Nível Ensino"] = parseEducationLevel(element["Grau de Ensino"]);
    element["Ano Escolaridade"] = parseSchoolYear(element["Ano Esc."]);
    element["Turma"] = element["Turma"];
    element["Nome"] = element["Nome"];
    element["NIF"] = !isNaN(Number(element["NIF"])) ? Number(element["NIF"]) : null;
    element["Data Início Serviço"] = parseSpecialDate(element["Data"]);
  });

  for (let j = 0; j < fileData.length; ) {
    let fileDataEl = fileData[j];
    if (fileDataEl["NIF"] == null) {
      fileData.splice(j, 1);
    } else {
      j++;
    }
  }

  function parseCourseType(course) {
    switch (course) {
      case "Secundário - Profissional":
        return "Profissional";
      case "3º ciclo - CEF":
        return "CEF";
      case "3º ciclo - PCA":
        return "PCA";
      default:
        return "Regular";
    }
  }

  function parseEducationLevel(educationLevel) {
    switch (educationLevel) {
      case "1º CEB":
        return "1º Ciclo";
      case "2º CEB":
        return "2º Ciclo";
      case "3º CEB":
        return "3º Ciclo";
      case "pré-escolar":
        return "PRE";
      case "Cursos Profissionais":
        return "SEC";
      case "Secundário":
        return "SEC";
      default:
        break;
    }
  }

  function parseSchoolYear(schoYear) {
    if (schoYear == "Jardim-de-Infância" || schoYear == "jardim_de_infncia" || schoYear == "pré-escolar") {
      return "0";
    }
    if (schoYear == "1º ano" || schoYear == "1.º ano" || schoYear == "1_ano" || schoYear == "1º") {
      return "1";
    }
    if (schoYear == "2º ano" || schoYear == "2.º ano" || schoYear == "2_ano" || schoYear == "2º") {
      return "2";
    }
    if (schoYear == "3º ano" || schoYear == "3.º ano" || schoYear == "3_ano" || schoYear == "3º") {
      return "3";
    }
    if (schoYear == "4º ano" || schoYear == "4.º ano" || schoYear == "4_ano" || schoYear == "4º") {
      return "4";
    }
    if (schoYear == "5º ano") {
      return "5";
    }
    if (schoYear == "6º ano") {
      return "6";
    }
    if (schoYear == "7º ano") {
      return "7";
    }
    if (schoYear == "8º ano") {
      return "8";
    }
    if (schoYear == "9º ano") {
      return "9";
    }
    if (schoYear == "10º ano") {
      return "10";
    }
    if (schoYear == "11º ano") {
      return "11";
    }
    if (schoYear == "12º ano") {
      return "12";
    }

    return null;
  }

  function parseASEEchelon(aseEchelon) {
    switch (aseEchelon) {
      case "escalão A":
        return "A";
      case "escalão B":
        return "B";
      case "sem escalão":
        return "Sem escalão";
      default:
        break;
    }
  }

  function parseSpecialDate(date) {
    if (date) {
      let parsedDate = date.split(" ");
      if (parsedDate.length > 1) {
        parsedDate = parsedDate[0];
        let splitDate = [];
        splitDate = parsedDate.split("/");
        if (splitDate.length == 3) {
          return new Date(splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2]).getTime();
        }
      }
      return null;
    }
  }
} */

function aecVFXSigaParseExcelData(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("Ano");
  headerNames.push("Agrupamento");
  headerNames.push("Escola");
  headerNames.push("Nível Ensino");
  headerNames.push("Ano Escolaridade");
  headerNames.push("Turma");
  headerNames.push("Nome");
  headerNames.push("NIF");
  headerNames.push("Escalão ASE");
  headerNames.push("Data Início Serviço");
  headerNames.push("Data Fim Serviço");

  let filledImporterFields = importerFieldsCheck(scope.importerFields);
  fileData.forEach((element) => {
    if (filledImporterFields.indexOf("year") != -1) {
      element["Ano"] = scope.importerFields.year;
    } else {
      element["Ano"] = new Date().getFullYear().toString();
    }
    element["Agrupamento"] = element["nomeAgrupamento"];
    element["Escola"] = element["nomeEscola"];
    element["Nível Ensino"] = parseEducationLevel(element["GrauEnsino"]);
    element["Ano Escolaridade"] = parseSchoolYear(element["AnoEscolaridade"]);
    element["Turma"] = element["NomeTurma"];
    element["Nome"] = element["nomeAluno"];
    element["NIF"] = !isNaN(Number(element["NIF"])) ? Number(element["NIF"]) : null;
    element["Escalão ASE"] = parseASEEchelon(element["EscalaoAS"]);
    element["Data Início Serviço"] = parseSpecialDate(element["DataInicioServico"]);
    element["Data Fim Serviço"] = parseSpecialDate(element["DataFimServico"]);
  });

  for (let j = 0; j < fileData.length; ) {
    let fileDataEl = fileData[j];
    if (fileDataEl["NIF"] == null) {
      fileData.splice(j, 1);
    } else {
      j++;
    }
  }

  if (filledImporterFields.indexOf("year") != -1) {
    // Delete rows selected year
    let rowsToDelete = scope.data.length > 0 ? scope.data.filter((el) => el.year == scope.importerFields.year) : [];
    if (rowsToDelete.length > 0) {
      scope.genericScope.parentScope.showDeterminateLoader(rowsToDelete.length);
      let numDeletedRows = 0;
      async.eachSeries(
        rowsToDelete,
        function iteratee(item, callback) {
          scope.genericScope.genericFactory.delete(item.id).then((el) => {
            scope.genericScope.parentScope.updateDeterminateLoader();
            numDeletedRows += 1;
            callback();
          });
        },
        function done() {
          if (numDeletedRows == rowsToDelete.length) {
            console.log("done");
            scope.genericScope.parentScope.$parent.hideLoader();
          }
        },
        function (err) {
          if (err) {
            console.log(err);
          } else {
            //Done
            console.log("done");
          }
        }
      );
    }
  }

  function parseEducationLevel(educationLevel) {
    switch (educationLevel) {
      case "1º CEB":
        return "1º Ciclo";
      default:
        break;
    }
  }

  function parseSchoolYear(schoYear) {
    if (schoYear == "Jardim-de-Infância" || schoYear == "jardim_de_infncia") {
      return "0";
    }
    if (schoYear == "1º ano" || schoYear == "1.º ano" || schoYear == "1_ano" || schoYear == "1º") {
      return "1";
    }
    if (schoYear == "2º ano" || schoYear == "2.º ano" || schoYear == "2_ano" || schoYear == "2º") {
      return "2";
    }
    if (schoYear == "3º ano" || schoYear == "3.º ano" || schoYear == "3_ano" || schoYear == "3º") {
      return "3";
    }
    if (schoYear == "4º ano" || schoYear == "4.º ano" || schoYear == "4_ano" || schoYear == "4º") {
      return "4";
    }

    return null;
  }

  function parseASEEchelon(aseEchelon) {
    switch (aseEchelon) {
      case "escalão A":
        return "A";
      case "escalão B":
        return "B";
      case "sem escalão":
        return "Sem escalão";
      default:
        break;
    }
  }

  function parseSpecialDate(date) {
    if (date) {
      let splitDate = [];
      splitDate = date.split("/");
      if (splitDate.length == 3) {
        return new Date(splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2]).getTime();
      }
    }
  }
}

function etiVFXRecordParseExcelData(scope, headerNames, fileData) {
  fileData.forEach((element) => {
    element["NIF"] = !isNaN(Number(element["NIF"])) ? Number(element["NIF"]) : null;
    element["Data Início Serviço"] = parseSpecialDate(element["Data Início Serviço"]);
    element["Data Fim Serviço"] = parseSpecialDate(element["Data Fim Serviço"]);
  });

  let addedYearNIFsRecords = [];
  for (let j = 0; j < fileData.length; ) {
    let fileDataEl = fileData[j];
    if (fileDataEl["NIF"] == null) {
      fileData.splice(j, 1);
    } else if (addedYearNIFsRecords.indexOf(fileDataEl["Ano"] + fileDataEl["NIF"]) != -1) {
      fileData.splice(j, 1);
    } else {
      addedYearNIFsRecords.push(fileDataEl["Ano"] + fileDataEl["NIF"]);
      j++;
    }
  }

  function parseSpecialDate(date) {
    if (date) {
      let splitDate = [];
      splitDate = date.split("/");
      if (splitDate.length == 3) {
        return new Date(splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2]).getTime();
      }
    }
  }
}

function etiVFXSigaParseExcelData(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("Ano");
  headerNames.push("Mês");
  headerNames.push("Agrupamento");
  headerNames.push("Escola");
  headerNames.push("Nível Ensino");
  headerNames.push("Ano Escolaridade");
  headerNames.push("Turma");
  headerNames.push("Nome");
  headerNames.push("NIF");
  headerNames.push("Tipo Horário");
  headerNames.push("Horário");
  headerNames.push("Escalão ASE");
  headerNames.push("NSE");
  headerNames.push("Comparticipação AAAF");
  headerNames.push("Comparticipação NSE");
  headerNames.push("Data Início Serviço");
  headerNames.push("Data Fim Serviço");

  let filledImporterFields = importerFieldsCheck(scope.importerFields);
  fileData.forEach((element) => {
    if (filledImporterFields.indexOf("year") != -1) {
      element["Ano"] = scope.importerFields.year;
    } else {
      element["Ano"] = new Date().getFullYear().toString();
    }
    if (filledImporterFields.indexOf("month") != -1) {
      element["Mês"] = getMonthWithNumberBefore(scope.importerFields.month);
    }
    element["Agrupamento"] = element["nomeAgrupamento"];
    element["Escola"] = element["nomeEscola"];
    element["Nível Ensino"] = parseEducationLevel(element["nomeUnidade"], element["GrauEnsino"]);
    element["Ano Escolaridade"] = parseSchoolYear(element["nomeUnidade"], element["AnoEscolaridade"]);
    element["Turma"] = element["NomeTurma"];
    element["Nome"] = element["nomeAluno"];
    element["NIF"] = !isNaN(Number(element["NIF"])) ? Number(element["NIF"]) : null;
    element["Tipo Horário"] = element["nomeUnidade"];
    element["Horário"] = parseETISchedule(element["nomeUnidade"], element["nomeSubUnidade"]);
    element["Escalão ASE"] = parseASEEchelon(element["EscalaoAS"]);
    element["NSE"] = parseNSE(element["NSE"]);
    element["Comparticipação AAAF"] = parseAAAFComparticipation(element);
    element["Data Início Serviço"] = parseSpecialDate(element["DataInicioServico"]);
    element["Data Fim Serviço"] = parseSpecialDate(element["DataFimServico"]);
  });

  let numberOfNSEStudsBySchoolMap = {};

  // Remove duplicates in fileData by name + NIF

  for (let j = 0; j < fileData.length; j++) {
    let fileDataEl = fileData[j];
    if (
      fileDataEl["Agrupamento"] == null ||
      fileDataEl["Escola"] == null ||
      fileDataEl["Nível Ensino"] == null ||
      fileDataEl["Ano Escolaridade"] == null ||
      fileDataEl["Turma"] == null ||
      fileDataEl["Nome"] == null ||
      fileDataEl["NIF"] == null ||
      fileDataEl["Tipo Horário"] == null ||
      fileDataEl["Horário"] == null ||
      fileDataEl["Horário"] == "Inválido"
    ) {
      fileData.splice(j, 1);
      continue;
    }

    if (fileDataEl["Nome"] != null && fileDataEl["NIF"] != null) {
      for (let w = 0; w < fileData.length; ) {
        let fileDataEl2 = fileData[w];
        let fileDataEl2DeletedFlag = false;
        if (
          w != j &&
          w > j &&
          fileDataEl2["Nome"] != null &&
          fileDataEl2["NIF"] != null &&
          fileDataEl["Nome"] == fileDataEl2["Nome"] &&
          fileDataEl["NIF"] == fileDataEl2["NIF"]
        ) {
          fileDataEl2DeletedFlag = true;
          fileData.splice(w, 1);
        }
        if (!fileDataEl2DeletedFlag) {
          w++;
        }
      }
    }

    // Setup number of NSE studs by school

    if (fileDataEl["NSE"] == "Sim") {
      let elSchool;
      if (fileDataEl["Escola"] == "JI Alhandra") {
        elSchool = "EB n.º 1 de Alhandra";
      } else {
        elSchool = fileDataEl["Escola"];
      }
      if (numberOfNSEStudsBySchoolMap[elSchool] == null) {
        numberOfNSEStudsBySchoolMap[elSchool] = 1;
      } else {
        numberOfNSEStudsBySchoolMap[elSchool] += 1;
      }
    }
  }

  // Setup NSE municipality costs

  for (let j = 0; j < fileData.length; j++) {
    let fileDataEl = fileData[j];

    let elSchool;
    if (fileDataEl["Escola"] == "JI Alhandra") {
      elSchool = "EB n.º 1 de Alhandra";
    } else {
      elSchool = fileDataEl["Escola"];
    }

    if (numberOfNSEStudsBySchoolMap[elSchool] != null) {
      if (numberOfNSEStudsBySchoolMap[elSchool] >= 1 && numberOfNSEStudsBySchoolMap[elSchool] <= 3) {
        fileDataEl["Comparticipação NSE"] = 350;
      } else if (numberOfNSEStudsBySchoolMap[elSchool] >= 4 && numberOfNSEStudsBySchoolMap[elSchool] <= 6) {
        fileDataEl["Comparticipação NSE"] = 450;
      } else if (numberOfNSEStudsBySchoolMap[elSchool] >= 7 && numberOfNSEStudsBySchoolMap[elSchool] <= 9) {
        fileDataEl["Comparticipação NSE"] = 550;
      } else if (numberOfNSEStudsBySchoolMap[elSchool] >= 10) {
        fileDataEl["Comparticipação NSE"] = 650;
      }
    }
  }

  if (filledImporterFields.indexOf("year") != -1 && filledImporterFields.indexOf("month") != -1) {
    // Delete rows selected year
    let rowsToDelete =
      scope.data.length > 0
        ? scope.data.filter(
            (el) =>
              el.year == scope.importerFields.year && el.month == getMonthWithNumberBefore(scope.importerFields.month)
          )
        : [];
    if (rowsToDelete.length > 0) {
      scope.genericScope.parentScope.showDeterminateLoader(rowsToDelete.length);
      let numDeletedRows = 0;
      async.eachSeries(
        rowsToDelete,
        function iteratee(item, callback) {
          scope.genericScope.genericFactory.delete(item.id).then((el) => {
            scope.genericScope.parentScope.updateDeterminateLoader();
            numDeletedRows += 1;
            callback();
          });
        },
        function done() {
          if (numDeletedRows == rowsToDelete.length) {
            console.log("done");
            scope.genericScope.parentScope.$parent.hideLoader();
          }
        },
        function (err) {
          if (err) {
            console.log(err);
          } else {
            //Done
            console.log("done");
          }
        }
      );
    }
  }

  function parseEducationLevel(scheduleType, educationLevel) {
    if (scheduleType != null && scheduleType != "") {
      switch (scheduleType) {
        case "AAAF":
          return "PRE";
        case "CAF":
          return "1º Ciclo";
        default:
          break;
      }
    } else {
      switch (educationLevel) {
        case "1º CEB":
          return "1º Ciclo";
        case "pré-escolar":
          return "PRE";
        default:
          break;
      }
    }
  }

  function parseSchoolYear(scheduleType, schoYear) {
    if (scheduleType != null && scheduleType != "") {
      if (scheduleType == "AAAF") {
        return "0";
      }
    }
    if (schoYear == "Jardim-de-Infância" || schoYear == "jardim_de_infncia" || schoYear == "pré-escolar") {
      return "0";
    }
    if (schoYear == "1º ano" || schoYear == "1.º ano" || schoYear == "1_ano" || schoYear == "1º") {
      return "1";
    }
    if (schoYear == "2º ano" || schoYear == "2.º ano" || schoYear == "2_ano" || schoYear == "2º") {
      return "2";
    }
    if (schoYear == "3º ano" || schoYear == "3.º ano" || schoYear == "3_ano" || schoYear == "3º") {
      return "3";
    }
    if (schoYear == "4º ano" || schoYear == "4.º ano" || schoYear == "4_ano" || schoYear == "4º") {
      return "4";
    }

    return null;
  }

  function parseETISchedule(scheduleType, schedule) {
    if (schedule != null) {
      let sanitizedSchedule = schedule.trim();
      if (scheduleType == "AAAF") {
        switch (sanitizedSchedule) {
          case "prolongamento final do dia":
            return "AAAF - Horário longo";
          case "horário completo":
            return "AAAF - Horário completo";
          case "PH":
            return "AAAF - Prolongamento parcial 15h30-17h30";
          case "acolhimento da manhã":
            return "AAAF - Acolhimento";
          default:
            return "Inválido";
        }
      } else if (scheduleType == "CAF") {
        switch (sanitizedSchedule) {
          case "acolhimento":
            return "CAF - Acolhimento";
          case "horário completo":
            return "CAF - Horário completo";
          case "PH":
            return "CAF - Prolongamento";
          default:
            return "Inválido";
        }
      } else {
        return "Inválido";
      }
    } else {
      return "Inválido";
    }
  }

  function parseASEEchelon(aseEchelon) {
    switch (aseEchelon) {
      case "escalão A":
        return "A";
      case "escalão B":
        return "B";
      case "sem escalão":
        return "Sem escalão";
      default:
        return "Sem escalão";
    }
  }

  function parseNSE(nse) {
    switch (nse) {
      case "sim":
        return "Sim";
      case "SIM":
        return "Sim";
      case "não":
        return "Não";
      default:
        return "Não";
    }
  }

  function parseAAAFComparticipation(el) {
    let etiSchedules = scope.genericScope.getFromTableData("VFX_ETI_Schedules");
    if (el["Tipo Horário"] == "AAAF" && (el["Escalão ASE"] == "A" || el["Escalão ASE"] == "B")) {
      if (el["Horário"] != null) {
        let filteredETISchedule = etiSchedules.filter((etiSchedule) => etiSchedule.schedule == el["Horário"]);
        if (filteredETISchedule.length) {
          return filteredETISchedule[0].comparticipation;
        }
      }
    }
  }

  function parseSpecialDate(date) {
    if (date) {
      let splitDate = [];
      splitDate = date.split("/");
      if (splitDate.length == 3) {
        return new Date(splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2]).getTime();
      }
    }
  }
}

function vfxMealRecordSIGAAndIPSSParseExcelData(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("Ano Letivo");
  headerNames.push("Agrupamento");
  headerNames.push("Escola");
  headerNames.push("Nível Ensino");
  headerNames.push("Mês");
  headerNames.push("Escalão ASE");
  headerNames.push("SIGA Requisições");
  headerNames.push("SIGA Presenças");
  headerNames.push("SIGA Presenças sem requisição");
  headerNames.push("IPSS Refeições servidas");

  let filledImporterFields = importerFieldsCheck(scope.importerFields);

  if (filledImporterFields.length != 2) {
    fileData = [];
  } else {
    fileData.forEach((element) => {
      if (filledImporterFields.indexOf("year") != -1) {
        element["Ano Letivo"] = scope.importerFields.year;
      }
      element["Agrupamento"] = element["Agrupamento"];
      element["Escola"] = element["Escola"];
      element["Nível Ensino"] = element["Nível Ensino"] == "PRE" ? " PRE" : element["Nível Ensino"];
      if (filledImporterFields.indexOf("month") != -1) {
        element["Mês"] = getMonthWithNumberBefore(scope.importerFields.month.toLowerCase());
      }
      element["Escalão ASE"] = element["Escalão ASE"];
      element["SIGA Requisições"] = element["SIGA Requisições"] != null ? Number(element["SIGA Requisições"]) : null;
      element["SIGA Presenças"] = element["SIGA Presenças"] ? Number(element["SIGA Presenças"]) : null;
      element["SIGA Presenças sem requisição"] = element["SIGA Presenças sem requisição"]
        ? Number(element["SIGA Presenças sem requisição"])
        : null;
      element["IPSS Refeições servidas"] = element["IPSS Refeições servidas"]
        ? Number(element["IPSS Refeições servidas"])
        : null;
    });

    //Remove duplicates according to module data

    for (let j = 0; j < fileData.length; ) {
      let fileDataEl = fileData[j];
      if (
        fileDataEl["Ano Letivo"] == null ||
        fileDataEl["Agrupamento"] == null ||
        fileDataEl["Escola"] == null ||
        fileDataEl["Nível Ensino"] == null ||
        fileDataEl["Mês"] == null ||
        fileDataEl["Escalão ASE"] == null ||
        ((fileDataEl["SIGA Requisições"] == null ||
          fileDataEl["SIGA Presenças"] == null ||
          fileDataEl["SIGA Presenças sem requisição"] == null) &&
          fileDataEl["IPSS Refeições servidas"] == null)
      ) {
        fileData.splice(j, 1);
        continue;
      }
      let duplicateElFlag = false;
      for (let k = 0; k < scope.data.length; k++) {
        let dataEl = scope.data[k];
        let dataElParsedSchool = scope.genericScope.parseValue(dataEl.school, "school", "Schools");
        if (
          dataEl.year == fileDataEl["Ano Letivo"] &&
          dataEl.month == fileDataEl["Mês"] &&
          dataElParsedSchool == fileDataEl["Escola"] &&
          dataEl.aseEchelon == fileDataEl["Escalão ASE"]
        ) {
          duplicateElFlag = true;
          fileData.splice(j, 1);
          break;
        }
      }
      if (!duplicateElFlag) {
        j++;
      }
    }
  }
}

function iptCostsParseExcelData(scope, headerNames, fileData) {
  //Remove duplicate 'Cliente' column
  let clienteOccurrencesIndex = [];
  for (var i = 0; i < headerNames.length; i++) {
    let header = headerNames[i];
    if (header == "Cliente") {
      clienteOccurrencesIndex.push(i);
    }
  }
  if (clienteOccurrencesIndex.length > 0 && headerNames[clienteOccurrencesIndex[1]] == "Cliente") {
    headerNames.splice(clienteOccurrencesIndex[1], 1);
  }

  fileData.forEach((element) => {
    element["Ano/Mês"] = parseYearMonth(element["Ano/Mês"]);
    element["Data do documento"] = new Date(element["Data do documento"]).getTime();
    element["Data de lançamento"] = new Date(element["Data de lançamento"]).getTime();
    element["Data de entrada"] = new Date(element["Data de entrada"]).getTime();
    element["Valor"] = parseAmount(element["Valor"]);
  });

  function parseYearMonth(yearMonth) {
    if (yearMonth) {
      let splitDateFromMonth = [];
      splitDateFromMonth = yearMonth.split("/");
      if (splitDateFromMonth.length == 2) {
        return splitDateFromMonth[0];
      }
    }
  }

  function parseSpecialDate(date) {
    if (date) {
      let splitDate = [];
      splitDate = date.split("/");
      if (splitDate.length == 3) {
        return new Date(splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2]).getTime();
      }
    }
  }

  function parseAmount(value) {
    if (value) {
      if (!Number.isNaN(parseFloat(value))) {
        value = value.replace(/,/gi, "");
        return parseFloat(value);
      }
    }
  }
}

function schoolComplexMaterialsCMSParseExcelData(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("Classificação");
  headerNames.push("Agrupamento");
  headerNames.push("Escola");
  headerNames.push("Área");
  headerNames.push("Equipamento");
  headerNames.push("Qtd");
  headerNames.push("Estado");
  headerNames.push("Medida Corretiva");
  headerNames.push("Zona");
  headerNames.push("Observações");

  fileData.forEach((element) => {
    element["Estado"] = parseState(element);
    element["Medida Corretiva"] = parseCorrectiveMeasure(element);
    element["Qtd"] = parseQuantity(element["Qtd"]);
  });

  function parseState(element) {
    if (element["Bom"]) {
      return "Bom";
    } else if (element["Aceitável"]) {
      return "Aceitável";
    } else if (element["Mau"]) {
      return "Mau";
    } else {
      return null;
    }
  }

  function parseCorrectiveMeasure(element) {
    if (element["Reparação"]) {
      return "Reparação";
    } else if (element["Aquisição"]) {
      return "Aquisição";
    } else if (element["Transf."]) {
      return "Transf.";
    } else if (element["Abate"]) {
      return "Abate";
    } else {
      return null;
    }
  }

  function parseQuantity(value) {
    if (value) {
      if (!Number.isNaN(parseFloat(value))) {
        value = value.replace(/,/gi, "");
        return parseFloat(value);
      }
    }
  }
}

function etiParseExcelData(scope, headerNames, fileData) {
  fileData.forEach((element) => {
    element["Rendimento Anual"] = parseSpecialFloat(element["Rendimento Anual"]);
    element["Impostos"] = parseSpecialFloat(element["Impostos"]);
    element["Sobretaxa IRS"] = parseSpecialFloat(element["Sobretaxa IRS"]);
    element["Segurança Social"] = parseSpecialFloat(element["Segurança Social"]);
    element["Habitação"] = parseSpecialFloat(element["Habitação"]);
    element["Saúde"] = parseSpecialFloat(element["Saúde"]);
    element["Agregado Familiar"] = parseSpecialFloat(element["Agregado Familiar"]);
    element["Meses"] = parseSpecialFloat(element["Meses"]);
    element["Valor Per Capita"] = parseSpecialFloat(element["Valor Per Capita"]);
    element["Horário"] = parseSchedule(element["Horário"]);
  });

  function parseSpecialFloat(value) {
    if (value != null) {
      if (!Number.isNaN(parseFloat(value))) {
        value = value.replace(/,/gi, "");
        return parseFloat(value);
      }
    }
  }

  function parseSchedule(schedule) {
    if (schedule != null) {
      if (schedule == "Almoço") {
        return ["Almoço"];
      } else if (schedule == "Prolongamento de Horário") {
        return ["Prolongamento de Horário"];
      } else if (schedule == "Almoço e Prolongamento de Horário") {
        return ["Almoço", "Prolongamento de Horário"];
      } else {
        return null;
      }
    }
  }
}

function etiMunParseExcelData(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("Ano Letivo");
  headerNames.push("NIF");
  headerNames.push("Situação");
  headerNames.push("Horário");

  fileData.forEach((element) => {
    element["Ano Letivo"] = element["Ano Letivo"];
    element["Situação"] = element["Situação"];
    element["NIF"] = element["NIF"];
    element["Horário"] = parseSchedule(element);
  });

  function parseSchedule(element) {
    let studSchedule = [];
    if (element["Recepção"] && element["Recepção"].indexOf("1") != -1) {
      studSchedule.push("Recepção");
    }
    if (element["Refeições"] && element["Refeições"].indexOf("1") != -1) {
      studSchedule.push("Refeições");
    }
    if (element["Prolongamento"] && element["Prolongamento"].indexOf("1") != -1) {
      studSchedule.push("Prolongamento");
    }
    if (studSchedule.length > 0) {
      return studSchedule;
    } else {
      return null;
    }
  }
}

function cmsTransports2020ParseExcelData(scope, headerNames, fileData) {
  headerNames.push("Informação de Transporte");

  fileData.forEach((element) => {
    if (element["Data Nascimento"] != null) {
      let parts = element["Data Nascimento"].split("/");
      element["Data Nascimento"] = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]).getTime();
    }
    element["Informação de Transporte"] = parseTransportInfo(element);
    element["Telemóvel"] = parseSpecialFloat(element["Telemóvel"]);
  });

  function parseTransportInfo(element) {
    let transportInfoEl = {};

    if (element["Origem"] != null) {
      transportInfoEl.origin = element["Origem"];
    }
    if (element["Destino"] != null) {
      transportInfoEl.destination = element["Destino"];
    }
    if (element["Transporte"] != null) {
      transportInfoEl.transport = element["Transporte"];
    }
    if (element["Senha"] != null) {
      transportInfoEl.ticket = element["Senha"];
    }
    if (element["Num Cartão"] != null) {
      transportInfoEl.cardNumb = element["Num Cartão"] + "";
    }
    if (element["Comparticipação"] != null) {
      transportInfoEl.comparticipation = parseSpecialFloat(element["Comparticipação"]);
    }
    if (element["Situação"] != null) {
      transportInfoEl.situation = element["Situação"];
    }

    if (Object.keys(transportInfoEl).length > 0) {
      return new Array(transportInfoEl);
    } else {
      return null;
    }
  }

  function parseSpecialFloat(value) {
    if (value) {
      if (!Number.isNaN(parseFloat(value))) {
        value = value.replace(/,/gi, "");
        return parseFloat(value);
      }
    }
  }
}

function cmsTransportsImportChargesParseExcelData(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("Ano Letivo");
  headerNames.push("Nome");
  headerNames.push("NIF");
  headerNames.push("Transportadora");
  headerNames.push("Senha");
  headerNames.push("Histórico de Carregamentos");

  let filledImporterFields = importerFieldsCheck(scope.importerFields);
  let uploadYear, uploadMonth;

  if (filledImporterFields.indexOf("year") != -1) {
    uploadYear = scope.importerFields.year;
  }
  if (filledImporterFields.indexOf("month") != -1) {
    uploadMonth = scope.importerFields.month;
  }

  fileData.forEach((element) => {
    element["Ano Letivo"] = uploadYear;
    element["Nome"] = element["Titular"];
    element["NIF"] = element["NIF"];
    element["Transportadora"] = "Carris Metropolitana";
    element["Senha"] = parseTicket(element);

    let ticketPrice = parseSpecialFloat(element["Preço"]);

    for (let k = 0; k < scope.data.length; k++) {
      let dataEl = scope.data[k];
      if (
        dataEl.year == element["Ano Letivo"] &&
        dataEl.nif == element["NIF"] &&
        dataEl.parsedTransport == "Carris Metropolitana" &&
        dataEl.parsedTicket == element["Senha"]
      ) {
        let chargedFlag = false;
        if (dataEl.chargeHistory != null && Array.isArray(dataEl.chargeHistory) && dataEl.chargeHistory.length > 0) {
          for (let j = 0; j < dataEl.chargeHistory.length; j++) {
            let chargeEvent = dataEl.chargeHistory[j];
            if (chargeEvent.month == uploadMonth) {
              chargedFlag = true;
              break;
            }
          }
        } else {
          dataEl.chargeHistory = [];
        }
        if (!chargedFlag) {
          dataEl.chargeHistory.push({
            month: uploadMonth,
            chargeFlag: ["Sim"],
            chargeFlagcheckbox: {
              Sim: true,
            },
            chargedValue: ticketPrice,
          });
        }
        element["Histórico de Carregamentos"] = dataEl.chargeHistory;
        break;
      }
    }

    /* if (element["Data Carregamento"] != null) {
      let parts = element["Data Carregamento"].split("/");
      element["Data Carregamento"] = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]).getTime();
    }
    if (element["Data Validade Carregamento"] != null) {
      let parts = element["Data Validade Carregamento"].split("/");
      element["Data Validade Carregamento"] = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]).getTime();
    } */
  });

  function parseTicket(element) {
    if (element["Título Carregado"].indexOf("Navegante Setúbal") != -1) {
      return "Navegante Municipal";
    } else if (element["Título Carregado"].indexOf("Navegante Municipal") != -1) {
      return "Navegante Municipal";
    } else if (element["Título Carregado"].indexOf("Navegante Metropolitano") != -1) {
      return "Navegante Metropolitano";
    }
  }

  function parseSpecialFloat(value) {
    if (value) {
      if (!Number.isNaN(parseFloat(value))) {
        value = value.replace(/,/gi, "");
        return parseFloat(value);
      }
    }
  }
}

function schoolAreaCMOParseExcelData(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("Agrupamento");
  headerNames.push("Escola");
  headerNames.push("Área Funcional");
  headerNames.push("Espaço");

  for (let i = 0; i < fileData.length; ) {
    let element = fileData[i];
    if (element.splitFlag) {
      break;
    }
    let newElement = {};
    newElement["Escola"] = element["Escola"];
    let clusterID = scope.genericScope
      .getFromTableData("Class_Plan_Schools")
      .filter((el) => el.school == element["Escola"]);
    if (Array.isArray(clusterID) && clusterID.length > 0) {
      clusterID = clusterID[0].cluster;
      let parsedCluster = scope.genericScope.getFromTableData("Class_Plan_Clusters").filter((el) => el.id == clusterID);
      newElement["Agrupamento"] = parsedCluster[0].cluster;

      for (const column in element) {
        if (Object.hasOwnProperty.call(element, column)) {
          const cellValue = element[column];
          if (column != "Escola") {
            if (cellValue != null && cellValue.indexOf("SIM") != -1) {
              let functionalAreaID = scope.genericScope
                .getFromTableData("School_Complex_FA_Spaces")
                .filter((el) => el.space == column);
              if (Array.isArray(functionalAreaID) && functionalAreaID.length > 0) {
                functionalAreaID = functionalAreaID[0].functionalArea;
                let parsedFunctionArea = scope.genericScope
                  .getFromTableData("School_Complex_FAs")
                  .filter((el) => el.id == functionalAreaID)[0].functionalArea;
                let newElementCopy = JSON.parse(JSON.stringify(newElement));
                newElementCopy["Área Funcional"] = parsedFunctionArea;
                newElementCopy["Espaço"] = column;
                newElementCopy.splitFlag = true;
                fileData.push(newElementCopy);
              }
            }
          }
        }
      }
    }

    fileData.splice(i, 1);
  }
}

function cmsNonTeachingStaffCEICosts(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("Ano Letivo");
  headerNames.push("Nº Mecanográfico");
  headerNames.push("Despesa CEI");

  let filledImporterFields = importerFieldsCheck(scope.importerFields);
  let uploadYear, uploadMonth;

  if (filledImporterFields.indexOf("year") != -1) {
    uploadYear = scope.importerFields.year;
  }
  if (filledImporterFields.indexOf("month") != -1) {
    uploadMonth = scope.importerFields.month;
  }

  for (let w = 0; w < fileData.length; ) {
    let element = fileData[w];
    element["Ano Letivo"] = getScholarYearFromCivilYearAndMonth(uploadYear, uploadMonth);
    element["Nº Mecanográfico"] = element["N.º"];
    let chargedFlag = false;

    for (let k = 0; k < scope.data.length; k++) {
      let dataEl = scope.data[k];
      if (
        dataEl.year == element["Ano Letivo"] &&
        dataEl.mechNumber == element["Nº Mecanográfico"] &&
        element["Nº Mecanográfico"] != null
      ) {
        chargedFlag = false;
        if (dataEl.ceiCosts != null && Array.isArray(dataEl.ceiCosts) && dataEl.ceiCosts.length > 0) {
          let alreadyChargedFlag = false;
          for (let j = 0; j < dataEl.ceiCosts.length; j++) {
            let chargeEvent = dataEl.ceiCosts[j];
            if (chargeEvent.civilYear == uploadYear && chargeEvent.month == uploadMonth) {
              alreadyChargedFlag = true;
              break;
            }
          }
          if (alreadyChargedFlag) {
            break;
          }
        } else {
          dataEl.ceiCosts = [];
        }
        if (!chargedFlag) {
          let costsObj = { civilYear: uploadYear, month: uploadMonth };
          if (!isNaN(Number(element["Seguro"]))) {
            costsObj.ceiInsurance = Number(element["Seguro"]);
          }
          if (!isNaN(Number(element["Bolsa"]))) {
            costsObj.ceiGrant = Number(element["Bolsa"]);
          }
          dataEl.ceiCosts.push(costsObj);
          element["Despesa CEI"] = dataEl.ceiCosts;
          chargedFlag = true;
        }
        break;
      }
    }
    if (!chargedFlag) {
      fileData.splice(w, 1);
    } else {
      w++;
    }
  }
}

function cmsNonTeachingStaffExpectedRevenue(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("Ano Letivo");
  headerNames.push("Nº Mecanográfico");
  headerNames.push("Receita");

  let filledImporterFields = importerFieldsCheck(scope.importerFields);
  let uploadYear, uploadMonth, uploadWorkdays;

  if (filledImporterFields.indexOf("year") != -1) {
    uploadYear = scope.importerFields.year;
  }
  if (filledImporterFields.indexOf("month") != -1) {
    uploadMonth = scope.importerFields.month;
  }
  if (filledImporterFields.indexOf("workdays") != -1) {
    uploadWorkdays = scope.importerFields.workdays;
  }

  for (let w = 0; w < fileData.length; ) {
    let element = fileData[w];
    element["Ano Letivo"] = getScholarYearFromCivilYearAndMonth(uploadYear, uploadMonth);
    element["Nº Mecanográfico"] = element["N.º"];
    let chargedFlag = false;
    for (let k = 0; k < scope.data.length; k++) {
      let dataEl = scope.data[k];
      if (
        dataEl.year == element["Ano Letivo"] &&
        dataEl.mechNumber == element["Nº Mecanográfico"] &&
        element["Nº Mecanográfico"] != null
      ) {
        chargedFlag = false;
        if (
          dataEl.expectedRevenue != null &&
          Array.isArray(dataEl.expectedRevenue) &&
          dataEl.expectedRevenue.length > 0
        ) {
          let alreadyChargedFlag = false;
          for (let j = 0; j < dataEl.expectedRevenue.length; j++) {
            let chargeEvent = dataEl.expectedRevenue[j];
            if (chargeEvent.civilYear == uploadYear && chargeEvent.month == uploadMonth) {
              alreadyChargedFlag = true;
              break;
            }
          }
          if (alreadyChargedFlag) {
            break;
          }
        } else {
          dataEl.expectedRevenue = [];
        }
        if (!chargedFlag) {
          let expectedRevenueObj = { civilYear: uploadYear, month: uploadMonth };
          expectedRevenueObj.expectedRevTotal = 0;
          if (!isNaN(Number(element["VENC. BASE"]))) {
            expectedRevenueObj.expectedRevNetMonthRemuneration = Number(element["VENC. BASE"]);
            expectedRevenueObj.expectedRevEmployerCharges =
              Math.round(0.2375 * expectedRevenueObj.expectedRevNetMonthRemuneration * 100) / 100;
            expectedRevenueObj.expectedRevTotal =
              expectedRevenueObj.expectedRevNetMonthRemuneration + expectedRevenueObj.expectedRevEmployerCharges;
          }
          /* if (!isNaN(Number(element["SUBS. REFEIÇÃO"]))) {
            expectedRevenueObj.expectedRevMealAllowance = Number(element["SUBS. REFEIÇÃO"]);
          } */
          expectedRevenueObj.expectedRevMealAllowance = uploadWorkdays * 6;
          expectedRevenueObj.expectedRevTotal += expectedRevenueObj.expectedRevMealAllowance;
          if (!isNaN(Number(element["ABONO FALHAS"]))) {
            expectedRevenueObj.expectedRevFaultAllowance = Number(element["ABONO FALHAS"]);
            expectedRevenueObj.expectedRevTotal += expectedRevenueObj.expectedRevFaultAllowance;
          }
          expectedRevenueObj.expectedRevTotal = Math.round(expectedRevenueObj.expectedRevTotal * 100) / 100;
          dataEl.expectedRevenue.push(expectedRevenueObj);
          element["Receita"] = dataEl.expectedRevenue;
          chargedFlag = true;
        }
        break;
      }
    }
    if (!chargedFlag) {
      fileData.splice(w, 1);
    } else {
      w++;
    }
  }
}

function cmsNonTeachingStaffCosts(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("Ano Letivo");
  headerNames.push("Nº Mecanográfico");
  headerNames.push("Despesa");

  let filledImporterFields = importerFieldsCheck(scope.importerFields);
  let uploadYear, uploadMonth;

  if (filledImporterFields.indexOf("year") != -1) {
    uploadYear = scope.importerFields.year;
  }
  if (filledImporterFields.indexOf("month") != -1) {
    uploadMonth = scope.importerFields.month;
  }

  for (let w = 0; w < fileData.length; ) {
    let element = fileData[w];
    element["Ano Letivo"] = getScholarYearFromCivilYearAndMonth(uploadYear, uploadMonth);
    element["Nº Mecanográfico"] = element["N.º"];
    let chargedFlag = false;

    for (let k = 0; k < scope.data.length; k++) {
      let dataEl = scope.data[k];
      if (
        dataEl.year == element["Ano Letivo"] &&
        dataEl.mechNumber == element["Nº Mecanográfico"] &&
        element["Nº Mecanográfico"] != null
      ) {
        chargedFlag = false;
        if (dataEl.costs != null && Array.isArray(dataEl.costs) && dataEl.costs.length > 0) {
          let alreadyChargedFlag = false;
          for (let j = 0; j < dataEl.costs.length; j++) {
            let chargeEvent = dataEl.costs[j];
            if (chargeEvent.civilYear == uploadYear && chargeEvent.month == uploadMonth) {
              alreadyChargedFlag = true;
              break;
            }
          }
          if (alreadyChargedFlag) {
            break;
          }
        } else {
          dataEl.costs = [];
        }
        if (!chargedFlag) {
          let costsObj = { civilYear: uploadYear, month: uploadMonth };
          costsObj.costsCareer = element["CARREIRA"];
          costsObj.costsCategory = element["CATEGORIA"];
          if (!isNaN(Number(element["VENC. BASE"]))) {
            costsObj.costsNetMonthRemuneration = Number(element["VENC. BASE"]);
          }
          if (!isNaN(Number(element["FALTAS"]))) {
            costsObj.costsFaults = Number(element["FALTAS"]);
          }
          if (!isNaN(Number(element["SUBS. FERIAS/ NATAL"]))) {
            costsObj.costsHolidaysChristAllowance = Number(element["SUBS. FERIAS/ NATAL"]);
          }
          if (!isNaN(Number(element["SUBS. REFEIÇÃO"]))) {
            costsObj.costsMealAllowance = Number(element["SUBS. REFEIÇÃO"]);
          }
          if (!isNaN(Number(element["DESPESAS REPRESENTAÇÃO"]))) {
            costsObj.costsRepresentationCosts = Number(element["DESPESAS REPRESENTAÇÃO"]);
          }
          if (!isNaN(Number(element["ABONO FALHAS"]))) {
            costsObj.costsFaultAllowance = Number(element["ABONO FALHAS"]);
          }
          if (!isNaN(Number(element["COMP.TER. CONTRATO"]))) {
            costsObj.costsCompContract = Number(element["COMP.TER. CONTRATO"]);
          }
          if (!isNaN(Number(element["TRABALHO EXTRAORD."]))) {
            costsObj.costsExtraWork = Number(element["TRABALHO EXTRAORD."]);
          }
          if (!isNaN(Number(element["PENSÃO"]))) {
            costsObj.costsPension = Number(element["PENSÃO"]);
          }
          if (!isNaN(Number(element["ACERTOS REMUNERATORIOS"]))) {
            costsObj.costsRemunAdjustments = Number(element["ACERTOS REMUNERATORIOS"]);
          }
          if (!isNaN(Number(element["OUTROS ABONOS"]))) {
            costsObj.costsOtherAllowances = Number(element["OUTROS ABONOS"]);
          }
          if (!isNaN(Number(element["OUTROS DESCONTOS"]))) {
            costsObj.costsOtherDiscounts = Number(element["OUTROS DESCONTOS"]);
          }
          if (!isNaN(Number(element["CGA"]))) {
            costsObj.costsCGA = Number(element["CGA"]);
          }
          if (!isNaN(Number(element["CRSS"]))) {
            costsObj.costsCRSS = Number(element["CRSS"]);
          }
          if (!isNaN(Number(element["TOTAL"]))) {
            costsObj.costsTotal = Number(element["TOTAL"]);
          }
          dataEl.costs.push(costsObj);
          element["Despesa"] = dataEl.costs;
          chargedFlag = true;
        }
        break;
      }
    }
    if (!chargedFlag) {
      fileData.splice(w, 1);
    } else {
      w++;
    }
  }
}

function cmsNonTeachingStaffInsuranceCosts(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("Ano Letivo");
  headerNames.push("Nº Mecanográfico");
  headerNames.push("Seguros AT");

  let filledImporterFields = importerFieldsCheck(scope.importerFields);
  let uploadYear, uploadMonth;

  if (filledImporterFields.indexOf("year") != -1) {
    uploadYear = scope.importerFields.year;
  }
  if (filledImporterFields.indexOf("month") != -1) {
    uploadMonth = scope.importerFields.month;
  }

  for (let w = 0; w < fileData.length; ) {
    let element = fileData[w];
    element["Ano Letivo"] = getScholarYearFromCivilYearAndMonth(uploadYear, uploadMonth);
    element["Nº Mecanográfico"] = element["N.º"];
    let chargedFlag = false;

    for (let k = 0; k < scope.data.length; k++) {
      let dataEl = scope.data[k];
      if (
        dataEl.year == element["Ano Letivo"] &&
        dataEl.mechNumber == element["Nº Mecanográfico"] &&
        element["Nº Mecanográfico"] != null
      ) {
        chargedFlag = false;
        if (dataEl.insuranceCosts != null && Array.isArray(dataEl.insuranceCosts) && dataEl.insuranceCosts.length > 0) {
          let alreadyChargedFlag = false;
          for (let j = 0; j < dataEl.insuranceCosts.length; j++) {
            let chargeEvent = dataEl.insuranceCosts[j];
            if (chargeEvent.civilYear == uploadYear && chargeEvent.month == uploadMonth) {
              alreadyChargedFlag = true;
              break;
            }
          }
          if (alreadyChargedFlag) {
            break;
          }
        } else {
          dataEl.insuranceCosts = [];
        }
        if (!chargedFlag) {
          if (!isNaN(Number(element["Prémio Total"]))) {
            dataEl.insuranceCosts.push({
              civilYear: uploadYear,
              month: uploadMonth,
              insuranceCharge: Math.round(Number(element["Prémio Total"]) * 100) / 100,
            });
            chargedFlag = true;
          }
          element["Seguros AT"] = dataEl.insuranceCosts;
        }
        break;
      }
    }
    if (!chargedFlag) {
      fileData.splice(w, 1);
    } else {
      w++;
    }
  }
}

function cmsMealRecord(scope, headerNames, fileData) {
  headerNames.length = 0;
  headerNames.push("Ano civil");
  headerNames.push("Ano letivo");
  headerNames.push("Mês");
  headerNames.push("Agrupamento/ENA");
  headerNames.push("Escola");
  headerNames.push("Nível Ensino");
  headerNames.push("Código Nível Ensino");
  headerNames.push("Escalão ASE");
  headerNames.push("Aluno");
  headerNames.push("Nr Proc Aluno");
  headerNames.push("Data Refeição");
  headerNames.push("Refeição Servida");
  headerNames.push("Refeição Vendida");
  headerNames.push("Tipo Prato");
  headerNames.push("Receita");

  let fromTableClusters = scope.genericScope.getFromTableData("Transp_CMS_Clusters");
  let fromTableSchools = scope.genericScope.getFromTableData("Transp_CMS_Schos");
  let fromTableEducationLevels = scope.genericScope.getFromTableData("Transp_Education_Levels");

  let filledImporterFields = importerFieldsCheck(scope.importerFields);
  let uploadYear, uploadMonth;

  if (filledImporterFields.indexOf("year") != -1) {
    uploadYear = scope.importerFields.year;
  }
  if (filledImporterFields.indexOf("month") != -1) {
    uploadMonth = scope.importerFields.month;
  }

  for (let w = 0; w < fileData.length; ) {
    let element = fileData[w];
    element["Ano civil"] = uploadYear;
    element["Ano letivo"] = getScholarYearFromCivilYearAndMonth(uploadYear, uploadMonth);
    let filteredCluster = fromTableClusters.filter((el) => el.cluster == element["Agrupamento"]);
    if (filteredCluster.length > 0) {
      element["Agrupamento/ENA"] = filteredCluster[0].id;
    } else {
      element["Agrupamento/ENA"] = null;
    }
    let filteredSchool = fromTableSchools.filter((el) => el.school == element["Escola"]);
    if (filteredSchool.length > 0) {
      element["Escola"] = filteredSchool[0].id;
      element.educationLevel = filteredSchool[0].educationLevel;
      element["Nível Ensino"] = fromTableEducationLevels.filter(
        (el) => el.id == filteredSchool[0].educationLevel[0]
      )[0].educationLevel;
      element["Código Nível Ensino"] = fromTableEducationLevels.filter(
        (el) => el.id == filteredSchool[0].educationLevel[0]
      )[0].codeIGEFE;
    } else {
      element["Escola"] = null;
    }
    element["Mês"] = uploadMonth.toLowerCase();
    element["Aluno"] = element["Nome Aluno"];
    element["Nr Proc Aluno"] = Number(element["Nr Proc Aluno"]);
    element["Data Refeição"] = parseSpecialDate(element["Data Refeição"]);
    element["Receita"] = Number(element["Receita"]);
    let chargedFlag = false;

    if (element["Agrupamento/ENA"] == null || element["Escola"] == null || element["Nr Proc Aluno"] == null) {
      fileData.splice(w, 1);
    } else {
      for (let k = 0; k < scope.data.length; k++) {
        let dataEl = scope.data[k];
        if (
          dataEl.year == element["Ano letivo"] &&
          dataEl.nrProcessoAluno == element["Nr Proc Aluno"] &&
          element["Nr Proc Aluno"] != null &&
          dataEl.mealDateMs == element["Data Refeição"] &&
          element["Data Refeição"] != null
        ) {
          chargedFlag = true;
          break;
        }
      }
      if (chargedFlag) {
        fileData.splice(w, 1);
      } else {
        w++;
      }
    }
  }

  function parseSpecialDate(date) {
    if (date) {
      let splitDate = [];
      splitDate = date.split("/");
      if (splitDate.length == 3) {
        return new Date(splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2]).getTime();
      }
    }
  }
}

function iptGeneralDepreciationsParseExcelData(scope, headerNames, fileData) {
  fileData.forEach((element) => {
    element["Ano"] = new Date().getFullYear().toString();
    element["Dt.incorp."] = new Date(element["Dt.incorp."]).getTime();
    element["Valor Aquisição"] = parseSpecialFloat(element["Valor Aquisição"]);
    element["Saldo Inicial"] = parseSpecialFloat(element["Saldo Inicial"]);
    element["Reforço"] = parseSpecialFloat(element["Reforço"]);
    element["Saldo Final"] = parseSpecialFloat(element["Saldo Final"]);
  });

  function parseSpecialFloat(value) {
    if (value) {
      if (!Number.isNaN(parseFloat(value))) {
        value = value.replace(/,/gi, "");
        return parseFloat(value);
      }
    }
  }
}

function iptResumedDepreciationsParseExcelData(scope, headerNames, fileData) {
  /* headerNames.length = 0;
  headerNames.push("Ano");
  headerNames.push("Por completar");
  headerNames.push("Imobilizado");
  headerNames.push("Sbnº");
  headerNames.push("Imobilizado_Subn.º");
  headerNames.push("Reforço");
  headerNames.push("Centro Custo");
  headerNames.push("Período"); */

  headerNames.length = 0;
  let fieldToHeaderNames = {};

  for (const field in scope.genericScope.activeTab.fields) {
    if (scope.genericScope.activeTab.fields.hasOwnProperty(field)) {
      const fieldDesc = scope.genericScope.activeTab.fields[field];
      headerNames.push(fieldDesc.label);
      fieldToHeaderNames[field] = fieldDesc.label;
    }
  }

  let filledImporterFields = importerFieldsCheck(scope.importerFields);
  for (let w = 0; w < fileData.length; ) {
    let toUploadEl = fileData[w];
    if (toUploadEl["Imob_Sub"] == null) {
      fileData.splice(w, 1);
    } else {
      w++;
    }

    if (filledImporterFields.indexOf("year") != -1) {
      toUploadEl["Ano"] = scope.importerFields.year;
    } else {
      toUploadEl["Ano"] = new Date().getFullYear().toString();
    }

    if (filledImporterFields.indexOf("period") != -1) {
      toUploadEl["Período"] = scope.importerFields.period;
    } else {
      toUploadEl["Período"] = Number(toUploadEl["Per"]);
    }

    toUploadEl["Sbnº"] = toUploadEl["Subnúmero"];
    toUploadEl["Imobilizado_Subn.º"] = toUploadEl["Imob_Sub"];
    toUploadEl["Reforço"] = parseSpecialFloat(toUploadEl["Depreciação normal"]);
    toUploadEl["Centro Custo"] = toUploadEl["Centro custo"];

    let latestSameDepreciationEls = scope.data.filter(
      (el) =>
        toUploadEl["Imobilizado_Subn.º"] == el.fixedAssetSubNum &&
        toUploadEl["Ano"] == el.year &&
        toUploadEl["Período"] > Number(el.period)
    );

    if (
      latestSameDepreciationEls != null &&
      Array.isArray(latestSameDepreciationEls) &&
      latestSameDepreciationEls.length > 0
    ) {
      if (latestSameDepreciationEls.length > 1) {
        latestSameDepreciationEls.sort(function (a, b) {
          if (a.period < b.period) {
            return 1;
          }
          if (a.period > b.period) {
            return -1;
          }
        });
        latestSameDepreciationEls = latestSameDepreciationEls[0];
      } else {
        latestSameDepreciationEls = latestSameDepreciationEls[0];
      }

      for (const field in latestSameDepreciationEls) {
        if (latestSameDepreciationEls.hasOwnProperty(field)) {
          const fieldValue = latestSameDepreciationEls[field];
          if (fieldToHeaderNames[field] != null && toUploadEl[fieldToHeaderNames[field]] == null) {
            toUploadEl[fieldToHeaderNames[field]] = fieldValue;
          }
        }
      }
      toUploadEl["Por completar"] = "Não";
    } else {
      toUploadEl["Por completar"] = "Sim";
    }
  }

  function parseSpecialFloat(value) {
    if (value) {
      if (!Number.isNaN(parseFloat(value))) {
        value = value.replace(/,/gi, "");
        return parseFloat(value);
      }
    }
  }
}

/* function kstkCMSTransportParseExcelData(scope, headerNames, fileData) {
  headerNames.push("Informação de Transporte");
  fileData.forEach((element) => {
    let transportInfoObj = {};
    transportInfoObj.origin = element["origin"];
    transportInfoObj.destination = element["destination"];
    transportInfoObj.ticket = element["ticket"];
    transportInfoObj.comparticipation = element["comparticipation"];
    transportInfoObj.transport = element["transport"];
    transportInfoObj.situation = element["situation"];
    element["Informação de Transporte"] = [transportInfoObj];
  });
} */

function iptIncomeParseExcelData(scope, headerNames, fileData) {
  headerNames.unshift("Ano");
  let filledImporterFields = importerFieldsCheck(scope.importerFields);
  fileData.forEach((element) => {
    if (filledImporterFields.indexOf("year") != -1) {
      element["Ano"] = scope.importerFields.year;
    } else {
      element["Ano"] = new Date().getFullYear().toString();
    }

    if (filledImporterFields.indexOf("period") != -1) {
      element["Período contábil"] = scope.importerFields.period;
    }

    element["Valor"] = parseSpecialFloat(element["Valor"]);
    element["Data de lançamento"] = new Date(element["Data de lançamento"]).getTime();
    checkAccountNumb(element);
  });

  function parseSpecialFloat(value) {
    if (value) {
      if (!Number.isNaN(parseFloat(value))) {
        value = value.replace(/,/gi, "");
        return parseFloat(value);
      }
    }
  }

  function checkAccountNumb(element) {
    if (element["Nº conta"] != null && typeof element["Nº conta"] == "string") {
      if (!(element["Nº conta"][0] == "7" && (element["Nº conta"][1] == "1" || element["Nº conta"][1] == "2"))) {
        element["Centro de lucro"] = "Outros Rendimentos";
      }
    }
  }
}

function vfxSocialAssessment(scope, headerNames, fileData) {
  headerNames.push("Área");
  headerNames.push("Valor");

  for (let j = 0; j < fileData.length; ) {
    let el = fileData[j];
    if (el.splitFlag) {
      break;
    }
    let splitEls = 0;
    let areas = ["VFX", "GL", "AML", "RLVT", "Continente", "ACS ET", "ARS LVT", "Distrito de Lisboa"];
    for (let i = 0; i < areas.length; i++) {
      let area = areas[i];
      if (el[area] != null && el[area] != null) {
        el["Valor"] = Number(el[area]);
        el["Área"] = area;
        el.splitFlag = true;
        fileData.push(JSON.parse(JSON.stringify(el)));
      }
    }
    fileData.splice(0, 1);
  }
}

function importerFieldsCheck(importerFields) {
  let filledImporterFields = [];
  if (Object.keys(importerFields).length > 0) {
    for (const field in importerFields) {
      if (importerFields.hasOwnProperty(field)) {
        const fieldValue = importerFields[field];
        if (fieldValue != null && fieldValue != "") {
          filledImporterFields.push(field);
        }
      }
    }
    return filledImporterFields;
  } else {
    return [];
  }
}
